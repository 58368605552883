@import url('https://fonts.googleapis.com/css2?family=Roboto:300,400,500,700&display=swap');

* {
    box-sizing: border-box;
}

body {
    font-family: 'Open Sans', 'Roboto', sans-serif;
    margin: 0;
}

html, body, #app, #app>div {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Open Sans', 'Roboto', sans-serif;
    color: #43525a;
}
